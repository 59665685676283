// extracted by mini-css-extract-plugin
export var absoluteSubmit = "case-studies-module--absoluteSubmit--y-B-j";
export var actions = "case-studies-module--actions--2ClXo";
export var caseStudy = "case-studies-module--caseStudy--2ar7p";
export var caseStudy__quote = "case-studies-module--caseStudy__quote--3cQUZ";
export var caseStudy__quote__quoteblock = "case-studies-module--caseStudy__quote__quoteblock--oNMAl";
export var caseStudy__stats = "case-studies-module--caseStudy__stats--YCkif";
export var chevron = "case-studies-module--chevron--fqDgu";
export var container = "case-studies-module--container--JDbcR";
export var focusVisible = "case-studies-module--focus-visible--IbQL-";
export var hsErrorMsg = "case-studies-module--hs-error-msg--BPrF3";
export var hs_submit = "case-studies-module--hs_submit--+g8ZP";
export var icon = "case-studies-module--icon--x+gYu";
export var input = "case-studies-module--input--oIxkb";