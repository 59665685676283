import React, {lazy, Suspense} from "react";
import { graphql } from 'gatsby';
import { structuredTextNotEmpty } from "../../../lib/functions/util-functions";
import CaseStudyStat from "../../../modules/stats/statBlocks/statBlockCaseStudy";
import {caseStudy, caseStudy__stats, caseStudy__quote, caseStudy__quote__quoteblock} from './case-studies.module.scss';

const StructuredText = lazy(() =>import("../../../components/structuredText/structuredText"));
const ShareBar = lazy(() =>import("../components/shareBar/shareBar"));
const PostTemplate = lazy(() =>import("../posts"));
const Quote = lazy(() =>import("../../../components/quote/quote"));
const RenderModules = lazy(() =>import("../../../lib/renders/renderModules"));
const RenderMedia = lazy(() =>import("../../../lib/renders/renderMedia"));

const CaseStudy = ({data, location, pageContext}) => {
  const {author, challengeContent, meta, quote, quoteMedia, results,seo, stats, supportingModules, title, ...postTemplateData} = data?.datoCmsPosttypeCaseStudy || {};

  return (
    <PostTemplate className={caseStudy} heroSize={'Tall'} title={title} pagecontext={pageContext} location={location} allPosts={data?.allDatoCmsPosttypeCaseStudy.caseStudies} {...postTemplateData}>
      
      <div className={caseStudy__stats} data-addsearch="exclude" >
        <div className="container">
          {
            <CaseStudyStat data={stats} />
          }
        </div>
      </div>

      <Suspense fallback="">

        <section className="lifted-fully">
          <div className="section__wrap">
            <div className="container">
              <ShareBar title={title} url={location.href} description={seo ? seo.description : ''}/>
              <StructuredText className="wysiwyg" data={challengeContent}/>
            </div>  
          </div>  
        </section>

        <div className={`${caseStudy__quote} container`}>

          <div className="wrapper">
            {
              quoteMedia && <RenderMedia data={quoteMedia} className="media"/>
            }
            {
              (author && quote) && 
              <div className={caseStudy__quote__quoteblock} >
                <Quote data={{'author': author, 'quote': quote}} icons={true} />
              </div>
            }
          </div>
        </div>

      {
        structuredTextNotEmpty(results)  && 
        <section className="Reversed lifted-fully">
          <div className="wrapper container">
            <StructuredText className="wysiwyg Reversed" data={results} />
          </div>
        </section>
      }
        
      {
        supportingModules && <RenderModules data={supportingModules} />
      }
    </Suspense>
  </PostTemplate>
  )
}

export default CaseStudy;

export const query = graphql`
  query PostQuery($id: String, $categories : [String]) {
    datoCmsPosttypeCaseStudy(originalId : {eq : $id}) {
      author
      categories{
        originalId
        slug
        title
      }
      challengeContent{
        blocks{
          __typename
          ...LinkFragment
        }
        links
        value
      }
      featuredImage{
        ...BackgroundImageFragment
      }
      hero{
        ...CopyLinkFragment
      }
      id
      locale
      model{
        name
      }
      quote
      quoteMedia{
        __typename
        ...VideoFragment
        ...ImageLayoutFragment
      }
      results{
        blocks{
          __typename
          ...LinkFragment
          ...ImageLayoutFragment
        }
        links
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      } 
      seo{
        description
      }
      slug
      stats{
        ...MultiFieldFragment
      }
      supportingModules{
        __typename
        ...ModuleTextContentFragment
      }
      title
    }

    allDatoCmsPosttypeCaseStudy(
      filter: {
        originalId: {ne: $id}, 
        categories: {elemMatch: {slug: {in: $categories}}},
        featuredImage: {url: {ne : "null" }},
      }
      limit: 10
      ){
      caseStudies: nodes{
        categories{
          slug
        }
        featuredImage{
          ...ThirdImageFragment
        }
        seo{
          description
        }
        slug
        title
      }
    }

  }
`